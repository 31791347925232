import { useState, useContext, useMemo, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { OrderContext } from "../../providers/OrderProvider";
import useReturnReasonService from "../../services/useReturnReasonService";
import useGetInvoiceInformation from "../../services/useGetInvoiceInformation";
import { getHostname } from "../../utils/location-utils";
import { isEmpty } from "lodash";
import { useSnackbar } from "../../hooks/useSnackBar";

import { ReturnReasonsOptions } from "../../entities/ReturnReasons";
import Path from "../../enums/Path";
import { Steps } from "../../enums/Steps";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";

import classes from "./ReturnsPage.module.scss";
import classNames from "classnames";

import LayoutSteps from "../../components/Common/LayoutSteps";
import SubNavbar from "../../components/Returns/SubNavbar";
import Title from "../../components/Common/Title";
import Paragraph from "../../components/Common/Paragraph";
import LoadSpinner from "../../components/Common/LoadSpinner";
import ReturnStatusHeader from "../../components/Returns/ReturnStatusHeader";

const ReturnsPage = () => {
  const {
    handleSetCurrentRma,
    currentRma,
    currentOrders,
    handleClearSessionStorage,
  } = useContext(OrderContext);
  const { handleSetCurrentInvoiceData } = useContext(OrderContext);
  const { getStatusOrderForm } = useGetInvoiceInformation();
  const { getReturnReason } = useReturnReasonService();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { addSnack } = useSnackbar();
  const [currentStep, setCurrentStep] = useState(Steps.STEP1);
  const [returnReasonsOptions, setReturnReasonsOptions] = useState<
    ReturnReasonsOptions[]
  >([]);
  const [step, setStep] = useState(
    sessionStorage?.getItem("step")
      ? (sessionStorage?.getItem("step") as Steps)
      : Steps.STEP1
  );

  const orderNumber = useMemo(
    () => searchParams.get("order-number") ?? undefined,
    [searchParams]
  );

  const zipCode = useMemo(
    () => searchParams.get("zip-code") ?? undefined,
    [searchParams]
  );

  useEffect(() => {
    if (Object.values(Steps).includes(step)) {
      setCurrentStep(step);
    }
  }, [step]);

  useEffect(() => {
    const previousUrl = window.localStorage.getItem("previousUrl");
    const currentUrl = location.pathname + location.search;
    if (previousUrl !== currentUrl) {
      handleClearSessionStorage();
      setStep(Steps.STEP3);
    }
    window.localStorage.setItem("previousUrl", currentUrl);
  }, [handleClearSessionStorage, location, step]);

  console.log("currentOrders :>> ", currentOrders);

  const hasEligibleProduct = currentOrders.some(
    (product) => product.eligible === true
  );

  console.log("hasEligibleProduct :>> ", hasEligibleProduct);

  const handleSetStep = (step: Steps) => {
    setStep(step);
    sessionStorage.setItem("step", step);
  };

  useEffect(() => {
    const fetchData = async () => {
      let errorMessage = "";
      try {
        setIsLoading(true);
        if (zipCode && orderNumber) {
          const response = await getStatusOrderForm({
            zipCode,
            orderNumber,
            domain: getHostname(),
          });
          if (response.invoice) {
            handleSetCurrentInvoiceData(response);
            if (!isEmpty(response.rmaInfo)) {
              // @ts-ignore
              handleSetCurrentRma(response.rmaInfo);
            }
            if (!isEmpty(response.rmaInfo.results)) {
              handleSetStep(Steps.STEP3);
            } else {
              handleSetStep(Steps.STEP1);
            }
          } else {
            navigate(Path.Home);
            if (response.statusCode === 404 || response.statusCode === 500) {
              errorMessage = `Order Number: ${orderNumber} with Zip Code: ${zipCode} not found`;
            } else if (response.statusCode === 409) {
              errorMessage = `This order has not been Shipped yet. Once Shipped you will be able to return it.`;
            } else {
              errorMessage = response?.message;
            }
            addSnack({
              display: true,
              severity: "error",
              message: errorMessage,
            });
          }
        }
      } catch (error) {
        console.error(error);
        navigate(Path.Home);
        errorMessage = `Order Number: ${orderNumber} with Zip Code: ${zipCode} not found`;
        addSnack({
          display: true,
          severity: "error",
          message: errorMessage,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStatusOrderForm, orderNumber, zipCode]);

  useEffect(() => {
    const fetchReturnReason = async () => {
      const response = await getReturnReason();
      setReturnReasonsOptions(
        response?.map((reason) => ({
          label: reason.name,
          value: reason,
        })) ?? []
      );
    };
    fetchReturnReason();
  }, [getReturnReason]);

  const getCurrentStep = (handleSetStep: (step: Steps) => void) => ({
    [Steps.STEP1]: <Step1 handleSetStep={handleSetStep} />,
    [Steps.STEP2]: (
      <Step2
        handleSetStep={handleSetStep}
        returnReasonsOptions={returnReasonsOptions}
      />
    ),
    [Steps.STEP3]: <Step3 handleSetStep={handleSetStep} zipCode={zipCode} />,
  });

  const status = currentRma?.status || "default";

  console.log(
    "notElegible :>> ",
    !hasEligibleProduct && currentStep !== Steps.STEP3
  );

  return (
    <>
      {isLoading ? (
        <LoadSpinner />
      ) : (
        <LayoutSteps>
          {!hasEligibleProduct && currentStep !== Steps.STEP3 ? (
            <>
              <header
                className={classNames(
                  classes.stepHeader,
                  classes.noItemsEligible
                )}
              >
                <Title kind="h1" size="l">
                  Whoops! No items are eligible for return.
                </Title>
                <Paragraph size="xxs">
                  We are sorry but no items in your order are eligible for a
                  return.
                </Paragraph>
                <Paragraph size="xxs">
                  Per our Terms and Conditions, returns must be returned within
                  30 days of purchase delivery.
                </Paragraph>
                <Paragraph size="xxs">
                  If you need further assistance, please contact our customer
                  service team.
                </Paragraph>
              </header>
            </>
          ) : (
            <>
              <ReturnStatusHeader
                status={status}
                step={step}
                handleSetStep={handleSetStep}
                currentStep={currentStep}
              />

              <SubNavbar step={step} handleSetStep={handleSetStep} />
            </>
          )}
          <section className={classes.steps}>
            {getCurrentStep(handleSetStep)[step]}
          </section>
        </LayoutSteps>
      )}
    </>
  );
};

export default ReturnsPage;
