import { useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import qs from "qs";
import { isEmpty } from "lodash";

import { Input, InputLabel, Box, CircularProgress } from "@mui/material";
import classes from "./Home.module.scss";
import Header from "../../components/Common/Header";
import Title from "../../components/Common/Title";
import Button from "../../components/Common/Button";
import Paragraph from "../../components/Common/Paragraph";
import ExternalLink from "../../components/Common/ExternalLink";
import MinorFooter from "../../components/Common/MinorFooter";
import usePortalLookAndFeel from "../../hooks/usePortalLookAndFeel";
import { useSnackbar } from "../../hooks/useSnackBar";
import useGetInvoiceInformation from "../../services/useGetInvoiceInformation";
import { useNavigate } from "react-router-dom";
import Path from "../../enums/Path";
import { getHostname } from "../../utils/location-utils";

type FormData = {
  orderNumber: string;
  zipCode: string;
};

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { addSnack } = useSnackbar();

  const { portalLookAndFeel } = usePortalLookAndFeel();
  const { getStatusOrderForm } = useGetInvoiceInformation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const { orderNumber, zipCode } = data;
    let errorMessage = "";
    try {
      setIsLoading(true);
      const response = await getStatusOrderForm({
        zipCode,
        orderNumber,
        domain: getHostname(),
      });
      if (!isEmpty(response.invoice)) {
        navigate(
          `${Path.ReturnsPage}?${qs.stringify({
            "order-number": data.orderNumber,
            "zip-code": data.zipCode,
          })}`
        );
        return;
      } else {
        if (response.statusCode === 404 || response.statusCode === 500) {
          errorMessage = `Order Number: ${orderNumber} with Zip Code: ${zipCode} not found`;
        } else if (response.statusCode === 409) {
          errorMessage = `This order has not been Shipped yet. Once Shipped you will be able to return it.`;
        } else {
          errorMessage = response?.message;
        }
        addSnack({
          display: true,
          severity: "error",
          message: errorMessage,
        });
      }
    } catch (e) {
      console.error(e);
      errorMessage = `Order Number: ${orderNumber} with Zip Code: ${zipCode} not found`;
      addSnack({
        display: true,
        severity: "error",
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
      reset();
    }
  };

  return (
    <div
      style={
        portalLookAndFeel?.backgroundImage
          ? { backgroundImage: `url(${portalLookAndFeel.backgroundImage})` }
          : { backgroundColor: portalLookAndFeel?.backgroundColor ?? "#FFFFFF" }
      }
      className={classNames(classes.mainContainer)}
    >
      <Header />
      <section className={classes.loginContent}>
        <div className={classes.login}>
          <header className={classes.loginHeader}>
            <Title
              className={
                portalLookAndFeel.homeTitleColor !== ""
                  ? classes.homeTitleColor
                  : ""
              }
            >
              {portalLookAndFeel.homeTitleText !== ""
                ? portalLookAndFeel.homeTitleText
                : "Return"}
            </Title>
            <Paragraph
              size="m"
              className={classNames(
                classes.subtitle,
                classes.homeDescriptionColor
              )}
            >
              {portalLookAndFeel.homeDescriptionText !== ""
                ? portalLookAndFeel.homeDescriptionText
                : "We accept returns of undamaged items within 30 days of purchase."}
            </Paragraph>
          </header>
          <form
            className={classes.trackYourOrderForm}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <Box className={classes.trackYourOrderFormContainer}>
              <Box>
                <Controller
                  control={control}
                  defaultValue=""
                  {...register("orderNumber", { required: true })}
                  render={({ field }) => (
                    <InputLabel>
                      Order Number
                      <Input {...field} />
                    </InputLabel>
                  )}
                />
                <div className={classes.submitError}>
                  {errors.orderNumber && "Order number is required"}
                </div>
              </Box>
              <Box>
                <Controller
                  control={control}
                  defaultValue=""
                  {...register("zipCode")}
                  render={({ field }) => (
                    <InputLabel>
                      {portalLookAndFeel?.zipPostalCode || "Zip Code"}
                      <Input
                        {...field}
                        inputProps={{
                          minLength: 5,
                          maxLength: 10,
                        }}
                      />
                    </InputLabel>
                  )}
                />
                <div className={classes.submitError}>
                  {Object.values(errors).map((e) => {
                    return e.message;
                  })}
                </div>
              </Box>
            </Box>
            <div className={classes.submitButtonWrapper}>
              <Button kind="primary" type="submit">
                {isLoading ? (
                  <CircularProgress
                    className={classes.circularProgress}
                    size="1rem"
                    sx={{ color: "white" }}
                  />
                ) : (
                  <span>Start Return</span>
                )}
              </Button>
            </div>
          </form>
          {portalLookAndFeel.helpLink && (
            <Paragraph size="xs" className={classes.disclaimer}>
              Can’t find your order?&nbsp;
              <ExternalLink
                to={portalLookAndFeel.helpLink}
                className={classes.helpLink}
              >
                Contact Support
              </ExternalLink>
            </Paragraph>
          )}
        </div>
      </section>
      <MinorFooter isLogin />
    </div>
  );
};

export default Home;
